<template>
  <div id="user-list">
    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in userTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                ---
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="gradeFilter"
            placeholder="Select Status"
            :items="gradeOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <!-- plan filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search Email"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <!-- <div class="d-flex align-center flex-wrap">
            <v-btn
              color="secondary"
              outlined
              class="mb-4"
            >
              <v-icon
                size="17"
                class="me-1"
              >
                {{ icons.mdiExportVariant }}
              </v-icon>
              <span>Export</span>
            </v-btn>
          </div> -->
        </v-col>
      </v-row>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :options.sync="options"
        :server-items-length="totalUserListTable"
        :loading="loading"
      >
        <!-- name & avatar-->
        <template #[`item.fullName`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="'primary'"
              :class="'v-avatar-light-bg primary--text'"
              size="32"
            >
              <!-- <v-img
                v-if="item.avatar"
                :src="require(`@/static/images/avatars/${item.avatar}`)"
              ></v-img> -->
              <span
                class="font-weight-medium"
              >{{ avatarText(item.email || item.channel_user_id) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                v-if="item.profile"
                :to="{ name : 'channel-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.profile.name || 'Name (?)' }}
              </router-link>
              <small>#{{ item.id }} - {{ item.channel_user_id }}</small>
            </div>
          </div>
        </template>

        <!-- email -->
        <template #[`item.email`]="{item}">
          <router-link
            :to="{ name : 'channel-user-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.email }}
          </router-link>
        </template>

        <!-- channel -->
        <template #[`item.channel_id`]="{item}">
          {{ item.channel.code }}
        </template>

        <!-- distributor -->
        <template #[`item.distributor_id`]="{item}">
          {{ item.distributor.slug }}
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'channel-user-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiFileDocumentOutline, mdiPlus, mdiSquareEditOutline } from '@mdi/js'

// sidebar
import { avatarText } from '@core/utils/filter'

import useUsersList from './useUsersList'

export default {
  components: {},
  setup() {
    const {
      userListTable,
      tableColumns,
      searchQuery,
      gradeFilter,
      gradeOptions,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,

      loadUsers,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList()

    return {
      userListTable,
      tableColumns,
      searchQuery,
      gradeFilter,
      gradeOptions,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,
      avatarText,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      loadUsers,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
