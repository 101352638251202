import { useTableOptions } from '@/composables'
import { fetchUsers } from '@api/user'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: 'USER', value: 'fullName', sortable: false },
    { text: 'EMAIL', value: 'email' },
    { text: 'GRADE', value: 'grade' },
    { text: 'CHANNEL', value: 'channel_id' },
    { text: 'Distributor', value: 'distributor_id' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const gradeFilter = ref(cached[2] || undefined)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const userTotalLocal = ref([])
  const selectedRows = ref([])

  const gradeOptions = ref([
    { title: 'no grade', value: 'null' },
    { title: 'VIP', value: 'vip' },
    { title: 'VVIP', value: 'vvip' },
  ])

  // fetch data
  const loadUsers = () =>
    fetchUsers(
      useTableOptions(options.value, {
        search: searchQuery.value,
        grade: gradeFilter.value === 'null' ? '' : gradeFilter.value,
      }),
    )
      .then(({ data }) => {
        console.log('response data', data)
        const { records, pagination, userTotal } = data.data

        userListTable.value = records
        totalUserListTable.value = pagination.total
        userTotalLocal.value = userTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery, gradeFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadUsers()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    gradeFilter,
    gradeOptions,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    loadUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
  }
}
