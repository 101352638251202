var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.userTotalLocal),function(total){return _c('v-col',{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" --- ")]),_c('span',[_vm._v(_vm._s(total.title))])]),_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserTotalIcon(total.title).color) + "--text"),attrs:{"color":_vm.resolveUserTotalIcon(total.title).color}},[_c('v-icon',{staticClass:"rounded-0",attrs:{"size":"25","color":_vm.resolveUserTotalIcon(total.title).color}},[_vm._v(" "+_vm._s(_vm.resolveUserTotalIcon(total.title).icon)+" ")])],1)],1)],1)],1)}),1),_c('v-card',[_c('v-card-title',[_vm._v(" Search & Filter ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"placeholder":"Select Status","items":_vm.gradeOptions,"item-text":"title","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.gradeFilter),callback:function ($$v) {_vm.gradeFilter=$$v},expression:"gradeFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search Email","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"options":_vm.options,"server-items-length":_vm.totalUserListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:'v-avatar-light-bg primary--text',attrs:{"color":'primary',"size":"32"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.email || item.channel_user_id)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[(item.profile)?_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'channel-user-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.profile.name || 'Name (?)')+" ")]):_vm._e(),_c('small',[_vm._v("#"+_vm._s(item.id)+" - "+_vm._s(item.channel_user_id))])],1)],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'channel-user-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.channel_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.channel.code)+" ")]}},{key:"item.distributor_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.distributor.slug)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{name:'channel-user-view',params:{id:item.id}}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }